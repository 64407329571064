<script>
  import Loader from "./../components/Loader.svelte";
  import { fade } from "svelte/transition";

  let clicked = false;

  let password = "";
  let code = "";

  const callAPI = async () => {
    const res = await fetch(
      `https://api.gifhorner-schuetzenfest.de/rest/code-check/`,
      {
        method: "post",
        body: JSON.stringify({
          password: password,
          code: code
        })
      }
    );
    return await res.json();
  };

  let response = false;

  const click = async () => {
    response = await callAPI();
    clicked = true;
  };
</script>

<style lang="scss">
  .container {
    width: 100%;
    text-align: center;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
    border-bottom: 3px solid $base-green;
    text-align: center;
    background-color: $base-white;
  }

  .button {
    background-color: $base-green;
    padding: 0.4rem 1rem;
    color: white;
    display: inline-block;
    width: auto;
    margin-top: 1.6rem;
    transition: 200ms;

    &:hover {
      background-color: rgba($base-green, $alpha: 0.8);
      box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    }
  }
</style>

<div class="container" in:fade={{ duration: 400 }}>

  <input bind:value={password} type="password" placeholder="Passwort" />
  <br />
  <input bind:value={code} type="text" placeholder="Code" />
  <br />
  <div class="button" on:click={click}>Überprüfen</div>
  <br />

  {#if clicked}
    {#await response}
      <Loader />
    {:then value}
      <br />
      {#if response.status == 'ok'}
        Der Code ist valide. Das Bier kann ausgehändigt werden und der Code
        wurde automatisch als eingelöst markiert.
      {:else}
        <p style="color: red">Fehler: {response.message}</p>
      {/if}
    {:catch error}
      <p style="color: red">Fehler bei der Verbindung: {error}</p>
    {/await}
  {/if}
</div>
