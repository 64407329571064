<script>
  import Card from "./../components/Card.svelte";
  import { DataManager } from "./../helper/data/DataManager";
  import Loader from "./../components/Loader.svelte";
  import FeedItem from "./../components/FeedItem.svelte";
  import { fade } from "svelte/transition";

  let fetch = DataManager.fetchVideos();
  let data = DataManager.store;

  import { Item } from "./../helper/data/Item";

  let firstMessage = new Item(
    "intro",
    "Wer wird Gifhorns Dancing Queen oder Dancing King?",
    "Tanze die Choreo zu Volkers Schlags Nach-Corona-Song „Komm tanzen wir“, mach davon ein Video und sende es per WhatsApp an die Redaktion. Und wenn Du magst, dann spendest Du auch noch für den Gifhorner Kinderfonds und nominierst beliebig viele Freunde, sich ebenfalls an der Challenge zu beteiligen. Stimmt alle fleißig mit dem Herzen ab und wählt den Sieger. ",
    "Wenn Du uns das Video per WhatsApp schickst, überträgst Du uns ein einfaches Nutzungsrecht und erklärst Dich mit der Veröffentlichung einverstanden. Sende uns bitte außerdem Deinen Namen und einen kurzen Text mit – und wenn Du Mitglied in einem Schützenverein bist, dann gerne auch Dein Korps und Deinen Zug.",
    {
      button: {
        name: "Jetzt Video einsenden!",
        link: "https://wa.me/49015156673368"
      }
    },
    Date.now() / 1000
  );
</script>

<style>
  .items {
    display: flex;
    flex-direction: column-reverse;
  }
</style>

<div in:fade={{ duration: 400 }}>
  <div class="items">

    {#if $data[0] === undefined}
      <Loader />
    {:else}
      {#each $data.filter(item => item.type === 'voting') as item}
        <Card itemData={item} />
      {/each}
    {/if}
    <Card itemData={firstMessage} />

  </div>
</div>
