<script>
  import Loader from "./../components/Loader.svelte";
  import { fade } from "svelte/transition";

  let amount = 1;
  let email = "";

  import { onMount } from "svelte";

  let payed = false;
  let response = false;
  let result = false;

  let wins = 0;

  onMount(() => {
    paypal
      .Buttons({
        createOrder: function(data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: String((3 * 0.9889 ** amount * amount).toFixed(1)),
                  currency_code: "EUR"
                }
              }
            ]
          });
        },
        onApprove: async function(data, actions) {
          return actions.order.capture().then(async function(details) {
            payed = true;

            const res = await fetch(
              "https://api.gifhorner-schuetzenfest.de/rest/checkout/",
              {
                method: "post",
                body: JSON.stringify({
                  id: details.id,
                  amount: amount,
                  email: email
                })
              }
            );
            const json = await res.json();
            response = json;
            console.log(json);

            setTimeout(() => {
              result = true;
              if (response.data) {
                wins = response.data.wins;
              }
            }, 5000);
          });
        }
      })
      .render("#paypal-button-container");
  });

  setInterval(() => {
    if (result) return;
    if (wins >= amount) {
      wins--;
    } else if (wins <= 0) {
      wins++;
    } else {
      if (Math.random() >= 0.5) {
        wins++;
      } else {
        wins--;
      }
    }
  }, 100);
</script>

<style lang="scss">
  h3 {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .slot {
    text-align: center;
    margin-top: 2rem;
  }

  input {
    font-size: 1rem;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
    border-bottom: 3px solid $base-green;
    text-align: center;
    background-color: $base-white;
  }

  .counter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $base-green;

    font-size: 2rem;
    cursor: pointer;

    .number {
      font-weight: 1000;
    }
  }

  p {
    font-size: 0.8rem;
  }

  .win {
    padding: 1rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;

    span {
      color: $base-green;
      font-weight: 1000;
    }
  }

  .hide {
    display: none;
  }

  .button {
    background-color: $base-green;
    padding: 0.4rem 1rem;
    color: white;
    margin-top: 1.6rem;
    transition: 200ms;
    text-align: center;
    font-size: 1rem;

    &:hover {
      background-color: rgba($base-green, $alpha: 0.8);
      box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    }
  }
</style>

<div in:fade={{ duration: 400 }}>
  <div class="slot">

    <img src="/logos/32.svg" alt="" />
    <h2>Herzlich Willkommen an der digitalen Losbude</h2>

    <p>
      Was wäre ein Schützenfest nur ohne Losbude?! Hier könnt Ihr köstliches
      Wittinger Premium gewinnen. Je mehr Lose Ihr kauft, desto geringer wird
      der Einzelpreis.
    </p>
    <br />

    <p>
      Die Gewinnchance bleibt gleich:
      <b>Jedes fünfte Los gewinnt!</b>
    </p>
    <br />
    <p>
      Jedes Gewinnlos kann gegen eine Kiste Wittinger Premium (24 x 0,33 l) bei
      Bierverlag Niebuhr in Gifhorn eingetauscht werden. So stillt Ihr nicht nur
      Euren Durst, sondern unterstützt in diesen schweren Zeiten auch den
      hiesigen Getränkehandel und unsere heimische Brauerei.
    </p>
    <br />

    {#if !payed}
      <h3>1. Anzahl an Losen auswählen</h3>
      <div class="counter">
        <div
          class="minus"
          on:click={() => (amount > 1 ? amount-- : (amount = 1))}>
          <i class="fad fa-minus-circle" />
        </div>
        <div class="number">{amount}</div>
        <div
          class="plus"
          on:click={() => (amount < 20 ? amount++ : (amount = 20))}>
          <i class="fad fa-plus-circle" />
        </div>
      </div>

      <div class="price">
        {String((3 * 0.9889 ** amount * amount).toFixed(1)).replace('.', ',')}0
        €
        <br />
        <i>
          Preis pro Los: {String((3 * 0.9889 ** amount).toFixed(1)).replace('.', ',')}0
          €
        </i>
      </div>

      <h3>2. Daten angeben</h3>
      <input
        type="email"
        placeholder="Gibt Deine Email an"
        bind:value={email} />
      <p>An diese Email wird der Gewinncode versendet.</p>
      {#if email != ''}
        <h3>3. Bezahlen und Lose einlösen</h3>
      {/if}
      <div class:hide={email == ''} id="paypal-button-container" />
    {:else if response}
      {#if response.status == 'ok'}
        <div class="win">
          <span style="font-size: {(wins / amount) * 5 + 1}rem">{wins}</span>
          <br />
          {amount == 1 ? 'Los' : 'Lose'} von {amount}
          {amount == 1 ? 'hat' : 'haben'} gewonnen!
        </div>

        <div
          on:click={() => {
            window.location.reload(false);
          }}
          class="button">
          Nochmal versuchen
        </div>
      {:else}
        <p style="color: red">
          Ein Fehler ist aufgetreten melde dich bitte unter 05371 – 9418001.
        </p>
      {/if}
    {:else}
      <Loader />
      <p>Danke für die Bezahlung. Deine Lose werden verarbeitet.</p>
    {/if}

  </div>
</div>
