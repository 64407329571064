<script>
  import Card from "./../components/Card.svelte";
  import { DataManager } from "./../helper/data/DataManager";
  import Loader from "./../components/Loader.svelte";
  import FeedItem from "./../components/FeedItem.svelte";
  import { fade } from "svelte/transition";

  let fetch = DataManager.fetchVideos();
  let data = DataManager.store;

  import { Item } from "./../helper/data/Item";

  let firstMessage = new Item(
    "intro",
    "Videos der Redaktion",
    "Hier findest Du alle möglichen Videos rund ums Gifhorner Online-Schützenfest 2020. Diese wurden entweder von der KURT-Redaktion produziert oder aus Euren Einsendungen ausgewählt. Viel Spaß beim Schauen!",
    "Selbstgedrehte Videos von allen, die sich an der Challenge beteiligen und das Schützenfest zu Hause feiern, findest Du im Tab „Challenge“.",
    {},
    Date.now() / 1000
  );
</script>

<style>
  .items {
    display: flex;
    flex-direction: column-reverse;
  }
</style>

<div in:fade={{ duration: 400 }}>
  <div class="items">

    {#if $data[0] === undefined}
      <Loader />
    {:else}
      {#each $data.filter(item => item.type === 'video') as item}
        <Card itemData={item} />
      {/each}
    {/if}
    <Card itemData={firstMessage} />

  </div>
</div>
