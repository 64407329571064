<script>
  import Card from "./../components/Card.svelte";
  import { DataManager } from "./../helper/data/DataManager";
  import Loader from "./../components/Loader.svelte";
  import FeedItem from "./../components/FeedItem.svelte";
  import { fade } from "svelte/transition";

  let fetch = DataManager.fetchVideos();
  let data = DataManager.store;

  import { Item } from "./../helper/data/Item";

  let totalDonations = 0;

  let firstMessage = new Item(
    "intro",
    `${totalDonations} € wurden bereits für den Gifhorner Kinderfonds „Kleine Kinder – immer satt“ gespendet!`,
    "Hilf auch Du den Kindern unserer Stadt mit einer Spende – die Höhe darfst Du selbstverständlich frei wählen!",
    "Alle Spenden im Rahmen des Spenden-Marathon werden mit Name und Summe in unserem Spenden-Ticker verewigt. Es kann auf Wunsch aber auch anonym gespendet werden.",
    {
      button: {
        name: "Jetzt spenden!",
        link: "/#/donate"
      }
    },
    Date.now() / 1000
  );

  setInterval(() => {
    totalDonations = DataManager.totalDonations;

    firstMessage.heading = `${totalDonations} € wurden bereits für den Gifhorner Kinderfonds „Kleine Kinder – immer satt“ gespendet!`;
  }, 1000);
</script>

<style>
  .items {
    display: flex;
    flex-direction: column-reverse;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
</style>

<div in:fade={{ duration: 400 }}>
  <img src="kinder.jpg" alt="" />

  <div class="items">

    {#if $data[0] === undefined}
      <Loader />
    {:else}
      {#each $data.filter(item => item.type === 'donation') as item}
        <Card itemData={item} />
      {/each}
      <Card itemData={firstMessage} />
    {/if}

  </div>
</div>
