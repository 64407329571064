import { Item } from './Item';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');

import { writable } from 'svelte/store';

export interface Video {
  children: any[];
  content: Content;
  files: any[];
  id: string;
  mediaUrl: string;
  mediaRoot: string;
  num: number;
  parent: string;
  slug: string;
  template: Template;
  translations: any[];
  uid: string;
  uri: string;
  url: string;
}

export interface Content {
  title: string;
  intro: string;
  id: string;
  date: string;
  link: string;
  likes: string;
}

export interface Template {}

export interface Voting {
  children: any[];
  content: VotingContent;
  files: any[];
  id: string;
  mediaUrl: string;
  mediaRoot: string;
  num: number;
  parent: string;
  slug: string;
  template: Template;
  translations: any[];
  uid: string;
  uri: string;
  url: string;
}

export interface VotingContent {
  title: string;
  intro: string;
  id: string;
  date: string;
  likes: string;
}

export interface Donation {
  amount: number;
  date: string;
  id: string;
  name: string;
}

export interface Template {}

export interface Article {
  children: any[];
  content: ArticleContent;
  files: string[];
  id: string;
  mediaUrl: string;
  mediaRoot: string;
  num: number;
  parent: string;
  slug: string;
  template: ArticleTemplate;
  translations: any[];
  uid: string;
  uri: string;
  url: string;
}

export interface ArticleContent {
  title: string;
  intro: string;
  date: string;
  text: string;
  likes: string;
  ips: string;
}

export interface ArticleTemplate {}

class Data {
  private videos: Item[] = [];
  private votings: Item[] = [];
  private articles: Item[] = [];
  private donations: Item[] = [];

  public store = writable([]);

  public get totalDonations(): number {
    //@ts-ignore
    return this.donations.reduce((acc, item) => acc + item.options.amount, 0);
  }

  constructor() {
    this.update();
    setInterval(() => {
      this.update();
    }, 5000);
  }

  private async update() {
    await this.fetchVideos();
    await this.fetchVotings();
    await this.fetchArticles();
    await this.fetchDonations();

    const items = [
      ...this.videos,
      ...this.votings,
      ...this.donations,
      ...this.articles,
    ].sort((a, b) => a.timestamp - b.timestamp);

    //@ts-ignore
    this.store.set(items);
  }

  public async fetchVideos() {
    const response = await this.callAPI('videos');
    const videos: Video[] = Object.values(response);

    this.videos = [];
    for (const video of videos.reverse()) {
      const item = new Item(
        'video',
        video.content.title,
        video.content.intro,
        'Klicke, um das Video einzublenden',
        {
          id: video.content.id,
          likes: Number(video.content.likes) > 0 ? video.content.likes : 0,
        },
        dayjs(video.content.date).unix(),
        video.uri
      );
      this.videos.push(item);
    }
  }

  public async fetchVotings() {
    const response = await this.callAPI('votings');
    const votings: Voting[] = Object.values(response);

    this.votings = [];
    for (const voting of votings.reverse()) {
      const item = new Item(
        'voting',
        voting.content.title,
        voting.content.intro,
        'Klicke, um die Einsendung einzublenden',
        {
          id: voting.content.id,
          likes: Number(voting.content.likes) > 0 ? voting.content.likes : 0,
        },
        dayjs(voting.content.date).unix(),
        voting.uri
      );
      this.votings.push(item);
    }
  }

  public async fetchArticles() {
    const response = await this.callAPI('articles');
    const articles: Article[] = Object.values(response);

    this.articles = [];
    for (const article of articles.reverse()) {
      const item = new Item(
        'article',
        article.content.title,
        article.content.intro,
        'Klicke, um den Text einzublenden',
        {
          text: article.content.text
            .replace('\n', '<br />')
            .replace('\n\n', '<br /> <br />'),
          image: article.files[0],
          likes: Number(article.content.likes) > 0 ? article.content.likes : 0,
        },
        dayjs(article.content.date).unix(),
        article.uri
      );
      this.articles.push(item);
    }
  }

  public async fetchDonations() {
    const response = await this.callAPI('donations');
    const donations: Donation[] = Object.values(response);

    this.donations = [];
    for (const donation of donations.reverse()) {
      const item = new Item(
        'donation',
        `${donation.amount} € für den Gifhorner Kinderfonds!`,
        `${donation.name ? donation.name : 'Ein anonymer Nutzer'} hat ${
          donation.amount
        } € für den Gifhorner Kinderfonds „Kleine Kinder – immer satt“ gespendet. Tausend Dank!`,
        'Klicke, um auch zu spenden!',
        { amount: donation.amount },
        dayjs(donation.date).unix(),
        'donation-' + donation.id
      );
      this.donations.push(item);
    }
  }

  public async like(id: string) {
    this.callAPI(`like/${id}`);
  }

  private async callAPI(path: string) {
    const res = await fetch(
      `https://api.gifhorner-schuetzenfest.de/rest/${path}`
    );
    const json = await res.json();
    if (res.ok) {
      return json;
    } else {
      throw new Error(String(res));
    }
  }
}

export const DataManager: Data = new Data();
