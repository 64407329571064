<script>
  import Loader from "./../components/Loader.svelte";
  import { fade } from "svelte/transition";

  let input = false;

  let inputName = "";
  let inputEmail = "";
  let inputAmount = 10;

  const validate = () =>
    inputName != "" && inputEmail != "" && inputAmount != 0;

  const callAPI = async () => {
    const res = await fetch(
      `https://api.gifhorner-schuetzenfest.de/rest/donate/`,
      {
        method: "post",
        body: JSON.stringify({
          name: inputName,
          email: inputEmail,
          amount: inputAmount
        })
      }
    );
    return await res.json();
  };

  let response = false;

  const submit = async () => {
    input = true;
    response = await callAPI();
  };
</script>

<style lang="scss">
  .container {
    width: 100%;
    text-align: center;
  }
  input {
    font-size: 1rem;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
    border-bottom: 3px solid $base-green;
    background-color: $base-white;
    text-align: center;
  }

  label {
    display: block;
    margin-bottom: -0.4rem;
  }

  p {
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }

  button {
    background-color: $base-green;
    padding: 0.4rem 1rem;
    color: white;
    margin-top: 1.6rem;
    transition: 200ms;
    text-align: center;
    font-size: 1rem;

    &:hover {
      background-color: rgba($base-green, $alpha: 0.8);
      box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    }
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
</style>

<div class="container" in:fade={{ duration: 400 }}>

  <img src="kinder.jpg" alt="" />

  <h3>Alles für den Gifhorner Kinderfonds „Kleine Kinder – immer satt“</h3>

  <p>
    Der Gifhorner Kinderfonds hat sich dem Kampf gegen die Kinderarmut
    verschrieben! Er ist für die Kinder aus finanziell schwachen Familien da,
    aber auch für alle anderen – so sorgt der Fonds dafür, dass sich alle Kinder
    und Jugendlichen in unserer Stadt wohlfühlen und möglichst chancengleich
    aufwachsen können.
  </p>
  <b>Unterstütze auch Du den Kinderfonds mit Deiner Spende:</b>
  <br />
  <br />
  {#if !input}
    <form on:submit|preventDefault={submit} action="">
      <label for="name">Dein Name:</label>
      <input
        type="text"
        name="name"
        placeholder="Dein Name"
        bind:value={inputName} />
      <p>Leer lassen, um anonym zu spenden.</p>
      <label for="email">Deine Email Adresse:</label>
      <input
        type="email"
        name="email"
        placeholder="Dein Email-Adresse"
        bind:value={inputEmail} />
      <p>Die Email wird nicht veröffentlicht.</p>
      <label for="amount">Höhe der Spende (€):</label>
      <input
        type="number"
        step="5"
        name="amount"
        placeholder="Der Betrag"
        bind:value={inputAmount} />

      {#if inputEmail != '' && inputAmount != 0}
        <br />
        <button>Absenden</button>
      {/if}
    </form>
  {:else}
    {#await response}
      <Loader />
    {:then value}
      <br />
      {#if value.status == 'ok'}
        Danke für Deine Spende! Du solltest demnächt eine Email erhalten!
      {:else}
        <p style="color: red">Fehler: {value.message}</p>
      {/if}
    {:catch error}
      <p style="color: red">Fehler bei der Verbindung: {error}</p>
    {/await}
  {/if}
</div>
