<script>
  import Card from "./../components/Card.svelte";
  import { DataManager } from "./../helper/data/DataManager";
  import { Item } from "./../helper/data/Item";
  import Loader from "./../components/Loader.svelte";
  import FeedItem from "./../components/FeedItem.svelte";
  import { fade } from "svelte/transition";

  let fetch = DataManager.fetchVideos();
  let data = DataManager.store;

  let firstMessage = new Item(
    "welcome",
    "Willkommen zum Gifhorner Online-Schützenfest 2020!",
    "Wie möchtest Du genannt werden?",
    `Bitte verwende einen nicht anstößigen und möglichst echten Namen, um nicht gesperrt zu werden. Dieser Name wird im Online-Chat verwendet. Er wird nur auf dem aktuellen Gerät gespeichert. Es findet keine Registrierung statt und Du brauchst keine E-Mail-Adresse anzugeben.`,
    {},
    Date.now() / 1000
  );
  let secondMessage = new Item(
    "whatsapp",
    "Beteilige Dich am Online-Schützenfest",
    "Gemeinschaft lebt vom Mitmachen! Zeige uns, wie Du das Schützenfest zu Hause feierst, oder sende uns Fotos und Videos, die Dich mit Freude an die vergangenen Schützenfeste erinnern. Wir veröffentlichen auch gerne Deinen Beitrag.",
    `Wenn Du uns Fotos oder Videos per WhatsApp schickst, überträgst Du uns ein einfaches Nutzungsrecht und erklärst Dich mit der Veröffentlichung einverstanden. Sende uns bitte außerdem Deinen Namen und einen kurzen Text mit – und wenn Du Mitglied in einem Schützenverein bist, dann gerne auch Dein Korps und Deinen Zug.`,
    {
      button: {
        name: "Jetzt Deinen Beitrag einsenden!",
        link: "https://wa.me/49015156673368"
      }
    },
    Date.now() / 1000
  );
</script>

<style>
  .items {
    display: flex;
    flex-direction: column-reverse;
  }
</style>

<div in:fade={{ duration: 400 }}>
  <div class="items">
    {#if $data[0] === undefined}
      <Loader />
    {:else}
      {#each $data as item, i}
        <Card itemData={item} background={true} />
      {/each}
    {/if}
    <Card itemData={secondMessage} />

    <Card itemData={firstMessage} />

  </div>
</div>
