<script>
  const logos = [];

  for (let index = 3; index < 41; index++) {
    const url = "/logos/" + index + ".svg";
    logos.push(url);
  }
  let links = [
    "https://www.emmerich-fleischerei.de/",
    "https://wiertz-genussvoll.de/",
    "https://www.gwg-gifhorn.de/",
    "",
    "",
    "http://www.raulfs.de/",
    "https://www.galipp-schuhmode.de/",
    "https://www.giesecke-catering.de/",
    "https://www.baukunst-becker.de/",
    "",
    "",
    "https://www.elektro-ohlhoff.de/home.html",
    "https://schützenheim-dannenbüttel.net/",
    "",
    "https://www.remondis-nord.de/sonderseiten/remondis-region-nord/",
    "https://www.pingel-kfz.de/",
    "https://www.bettina-harms.de/",
    "http://www.cdu-stadt-gifhorn.de/",
    "https://rpt.de/",
    "https://schuetzen-wiese.de/",
    "https://sprengel-dach.de/",
    "https://kirche-im-brauhaus.de/",
    "https://www.kultbahnhof-gifhorn.de/",
    "https://www.stadtwerke-gifhorn.de/",
    "https://www.autohaus-kuehl.de/",
    "https://www.henke-bedachungen.de/",
    "",
    "https://www.huk.de/vm/holger.dittombee/vm-mehr-info.html",
    "https://www.notbom.de/",
    "https://wittinger.de/",
    "https://www.leifert.de/",
    "https://www.stadthalle-gifhorn.de/home.html",
    "https://immo24-heuchert.de/",
    "https://www.physiocompact-gf.de/",
    "https://www.sparkasse-cgw.de/de/home.html",
    "https://www.stadt-gifhorn.de/sv_gifhorn/",
    "https://www.metallbaugieseke.de/CM/",
    "https://www.spd-gifhorn.de/"
  ];
  let items = [];
  for (const i in logos) {
    items.push({
      image: logos[i],
      url: links[i]
    });
  }

  for (let i = logos.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [logos[i], logos[j]] = [logos[j], logos[i]];
  }
</script>

<style lang="scss">
  footer {
    padding: 1rem;
  }

  .links {
    margin-top: 3rem;
    padding: 1rem 0;
    a {
      border-bottom: solid 2px $base-green;
    }
  }

  .banner {
    width: 100%;
    color: white;
    background-image: linear-gradient(to right, #00678d, #20476f);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1rem;
    padding: 0rem 0.5rem;

    img {
      height: 4rem;
    }

    i {
      font-size: 1.6rem;
    }
  }

  .sponsors {
    img {
      height: 2rem;
      margin: 0.4rem;
    }
  }
</style>

<footer>
  <h3>Sponsoren:</h3>
  <div class="sponsors">
    {#each items as item}
      <a href={item.url} target="_blank">
        <img src={item.image} alt="" />
      </a>
    {/each}
  </div>

  <div class="links">
    <a href="https://kurt-gifhorn.de/impressum">Impressum</a>
    <a href="https://kurt-gifhorn.de/datenschutz">Datenschutz</a>
  </div>

  <a target="_blank" href="https://kurt-gifhorn.de">
    <div class="banner">
      <img src="k.svg" alt="KURT" />
      <b>Eine Aktion von KURT – Dein Magazin für Gifhorn</b>
      <i class="fad fa-long-arrow-right" />

    </div>
  </a>
</footer>
