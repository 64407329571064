<script lang="ts">
  export interface IFeedItem {
    type: "video" | "voting" | "win";
    icon: string;
    header: string;
    body: string;
    footer: string;
  }

  export let data: IFeedItem;

  let icon = "laugh-beam";
</script>

<style lang="scss">
  .item {
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    padding: 1.4rem 1rem;
    border-radius: 10px;

    display: flex;
  }

  .icon {
    padding: 1rem;
    margin-right: 1rem;
    i {
      font-size: 2.4rem;
      color: $base-green;
    }
  }

  .text {
    .header {
      font-size: 1.2rem;
      font-family: $font-display;
      font-weight: 800;
    }

    .footer {
      font-size: 0.8rem;
      line-height: 0.85rem;
      color: rgba($base-black, $alpha: 0.5);
      padding-top: 1rem;
    }
  }
</style>

<div class="item">
  <div class="icon">
    <i class="fad fa-{icon}" />
  </div>
  <div class="text">
    <div class="header">Wilkommen zum Online-Schützenfest 2020</div>
    <div class="body">Wie willst Du genannt werden?</div>
    <div class="footer">
      Bitte verwende einen nicht anstößigen und möglichst echten Namen, um nicht
      gesperrt zu werden. Dieser Name wird im Online-Chat und Leaderboard
      angezeigt. Bei anderen Verwendungen wirst du vorher gefragt. Dieser Name
      wird nur auf dem aktuellen Gerät gespeichert. Es findet keine
      Registrierung statt und Du musst deine Email-Adresse nicht angeben.
    </div>
  </div>
</div>
