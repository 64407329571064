<script lang="ts">
  import io from "socket.io-client";
  const socket = io("https://beta.kurtgf.uber.space", {
    path: "/server"
  });
  import { GlobalData } from "./../helper/data/GlobalData";

  let name: any = GlobalData.name;

  let formName: string = "";

  let messages: any[] = [];

  let text: string = "";

  let open: boolean = false;

  if (window.innerWidth > 900) {
    open = true;
  }

  let currentVideo = GlobalData.video;

  messages.push({
    name: "Chatbot",
    text:
      "Willkommen im Live-Chat. Kommuniziere hier mit allen anderen Nutzerinnen und Nutzern! Bitte achte auf einen freundlichen Umgangston und spamme nicht. Andernfalls kann es sein, dass Deine Nachrichten nicht mehr alle durchkommen oder Du gesperrt wirst.",
    video: "Schaut ganz genau auf Deine Nachrichten"
  });

  socket.on("message", function(msg: any) {
    messages = [...messages, msg];
  });

  const input = () => {
    if ($name != null) {
      if (text.length < 1) return;
      socket.emit("message", {
        name: $name,
        msg: text,
        video: $currentVideo
      });
      text = "";
    } else {
      GlobalData.name.change(formName);
    }
  };

  import { fade } from "svelte/transition";
</script>

<style lang="scss">
  .container {
    width: $chat-width - 0.5rem;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 40px 0px rgba($base-black, $alpha: 0.1);

    &.hidden {
      display: none;
    }
    overflow: scroll;

    @media all and (max-width: $breakpoint) {
      left: 0;
      width: 100%;
      height: auto;
      bottom: 0rem;
      padding-bottom: 4rem;
    }
  }

  .messages {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.8rem;
    padding-bottom: 3rem;
    overflow: scroll;
    font-size: 0.8rem;

    .message {
      margin: 0.2rem;

      .header {
        b {
          color: $base-green;
          font-size: 0.9rem;
        }
      }

      .footer {
        font-style: italic;
        font-size: 0.8rem;
        color: rgba($base-black, 0.4);
      }
    }
  }

  form {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $base-white;
    width: 100%;
    // padding: 1rem;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: $breakpoint) {
      bottom: 4.5rem;
    }

    input {
      font-size: 1rem;
      width: 100%;
      padding: 0rem 1rem;
      background-color: $base-white;
      margin-top: 0.5rem;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0rem 1rem;
      font-size: 0.6rem;
      color: rgba($base-black, 0.4);

      &.error {
        color: red;
      }
    }

    button {
      background-color: $base-green;
      font-size: 1.4rem;
      padding: 0.4rem 0.2rem;
      color: white;
      width: 3rem;
      cursor: pointer;
    }
  }

  .toggle {
    position: fixed;
    top: 0rem;
    right: 1rem;
    font-size: 2rem;
    color: $base-green;
    z-index: 100;
    cursor: pointer;
  }
</style>

<div on:click={() => (open = !open)} class="toggle">
  {#if open}
    <i class="fad fa-comment-times" />
  {:else}
    <i class="fad fa-comment-plus" />
  {/if}
</div>
<div class:hidden={!open} class="container">
  <div class="messages">
    {#each messages as message}
      <div in:fade={{ duration: 200 }} class="message">
        <div class="header">
          <b>{message.name}</b>
        </div>
        <div class="body">{message.text}</div>
        <div class="footer">{message.video}</div>
      </div>
    {/each}
  </div>
  <form on:submit|preventDefault={input} action="">
    {#if $name != null}
      <span class:error={text.length > 200}>{text.length}/200</span>

      <input
        bind:value={text}
        placeholder="Schreibe eine nette Nachricht … "
        name="message"
        autocomplete="off" />
    {:else}
      <input
        bind:value={formName}
        placeholder="Gib Deinen Namen an"
        name="name"
        autocomplete="off" />
    {/if}
    <button>
      <i class="fa fa-chevron-right" />
    </button>
  </form>
  <div />
</div>
