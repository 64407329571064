<script lang="ts">
  import { GlobalData } from "./../helper/data/GlobalData";
  import { DataManager } from "./../helper/data/DataManager";
  import Loader from "./Loader.svelte";
  import Player from "./Player.svelte";
  import { fly, slide } from "svelte/transition";

  import { Item } from "./../helper/data/Item";
  export let itemData: Item;
  let open = false;

  export let background = true;
  import { push } from "svelte-spa-router";

  const click = () => {
    if (
      itemData.type == "video" ||
      itemData.type == "voting" ||
      itemData.type == "article"
    )
      open = !open;
    if (itemData.type == "donation") push("/donate");

    if (itemData.type == "video" || itemData.type == "voting") {
      open ? GlobalData.video.set(itemData.heading) : GlobalData.video.set("");
    }
  };

  if (!localStorage.getItem("likes")) {
    localStorage.setItem("likes", JSON.stringify([]));
  }

  let likes = JSON.parse(localStorage.getItem("likes"));

  let liked = likes.includes(itemData.id);

  const like = () => {
    if (liked) return;
    liked = true;
    localStorage.setItem(
      "likes",
      JSON.stringify([
        ...JSON.parse(localStorage.getItem("likes")),
        itemData.id
      ])
    );
    itemData.options.likes++;
    DataManager.like(itemData.id);
  };

  const nameHandler = function() {
    GlobalData.name.change(this.value);
  };

  let name = GlobalData.name;

  export interface Vimeo {
    type: string;
    version: string;
    provider_name: string;
    provider_url: string;
    title: string;
    author_name: string;
    author_url: string;
    is_plus: string;
    account_type: string;
    html: string;
    width: number;
    height: number;
    duration: number;
    description: string;
    thumbnail_url: string;
    thumbnail_width: number;
    thumbnail_height: number;
    thumbnail_url_with_play_button: string;
    upload_date: string;
    video_id: number;
    uri: string;
  }

  // Vimeo Data
  const getVideoData = async (): Vimeo => {
    const res = await fetch(
      `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${itemData.options.id}`
    );
    const json = await res.json();
    if (res.ok) {
      return json;
    } else {
      throw new Error(res);
    }
  };
  const videoData: Vimeo =
    itemData.options.id && background ? getVideoData() : true;
</script>

<style lang="scss">
  .item {
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    padding: 1.4rem 1rem;
    border-radius: 10px;

    display: flex;

    margin: 1rem 0;
    cursor: pointer;
  }

  .highlight {
    background-color: rgba($base-green, $alpha: 0.2);
    // color: white !important;

    // * {
    //   color: white !important;
    // }
  }

  .icon {
    padding: 1rem;
    margin-right: 1rem;
    i {
      font-size: 2.4rem;
      color: $base-green;
    }
  }

  .text {
    width: 100%;
    .header {
      font-size: 1.2rem;
      font-family: $font-display;
      font-weight: 800;
    }

    .footer {
      font-size: 0.8rem;
      line-height: 0.85rem;
      color: rgba($base-black, $alpha: 0.5);
      padding-top: 1rem;

      b {
        display: block;
        margin-top: 0.5rem;
      }
    }

    .content {
      margin: 2rem 0;
      width: 100%;
    }
  }

  .like {
    // background-color: rgba($base-red, $alpha: 0.7);
    width: 10rem;
    border-radius: 10px;

    font-size: 2rem;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1rem;

    transition: 200ms;

    color: rgba($base-red, $alpha: 0.7);

    display: flex;
    flex-direction: column;

    span {
      font-size: 0.9rem;
      text-align: center;
    }

    &:hover {
      font-size: 2.8rem;
    }
  }

  input {
    display: block;
    font-size: 1rem;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
    border-bottom: 3px solid $base-green;
  }

  @media all and (max-width: $breakpoint) {
    .item {
      display: block;
    }

    .icon {
      padding: 0;
      padding: 1rem 0;
      font-size: 1rem;
    }

    .like {
      width: 100%;
      margin-left: 0rem;
      padding: 0rem;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      height: 2rem;

      span {
        margin-left: 1rem;
      }
    }
  }

  .button {
    background-color: $base-green;
    padding: 0.4rem 1rem;
    color: white;
    display: inline-block;
    width: auto;
    margin-top: 1.6rem;
    transition: 200ms;

    &:hover {
      background-color: rgba($base-green, $alpha: 0.8);
      box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    }
  }

  .image-container {
    padding: 1rem 0;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }
</style>

{#await videoData}
  <Loader />

{:then value}
  <div
    class="item"
    class:highlight={itemData.type == 'intro' || itemData.type == 'whatsapp'}
    transition:fly={{ x: -200, duration: 200 }}>
    <div class="icon" on:click={click}>
      <i
        class="{itemData.icon == 'whatsapp' ? 'fab' : 'fad'} fa-{itemData.icon}" />
    </div>
    <div class="text" on:click={click}>
      <div class="header">{itemData.heading}</div>
      <div class="body">
        {itemData.body}
        {#if itemData.options.image}
          <div class="image-container">

            <img
              src={'https://api.gifhorner-schuetzenfest.de/' + itemData.options.image}
              alt="" />
          </div>
        {/if}
        {#if value.thumbnail_url && !open}
          <div class="image-container">
            <img
              src={value.thumbnail_url.split('_')[0] + '_1280x720.jpg'}
              alt="" />
          </div>
        {/if}
        {#if itemData.type === 'welcome'}
          <input
            value={$name}
            on:change={nameHandler}
            placeholder="Gib Deinen Namen an"
            name="name"
            autocomplete="off" />
        {/if}
        {#if itemData.options.button}
          <br />
          <a target="_blank" href={itemData.options.button.link}>
            <div class="button">{itemData.options.button.name}</div>
          </a>
        {/if}
      </div>
      <div class="footer">
        {itemData.footer}
        <b>{itemData.time}</b>
      </div>
      {#if open}
        {#if itemData.type == 'article'}
          <br />
          <p>
            {@html itemData.options.text}
          </p>
          <br />
        {/if}
        {#if itemData.type == 'video' || itemData.type == 'voting'}
          <!-- content here -->

          {#if itemData.options.id}
            <div transition:slide class="content">
              <Player videoId={itemData.options.id} />
            </div>
          {:else}
            <Loader />
          {/if}
        {/if}
      {/if}
    </div>

    {#if itemData.type != 'welcome' && itemData.type != 'intro'}
      <div class="like" on:click={like}>

        {#if liked}
          <i
            transition:fly={{ y: -200, duration: 200 }}
            class="fad fa-heart liked" />
        {:else}
          <i class="fal fa-heart" />
        {/if}

        {#if itemData.options.likes !== undefined}
          {#if itemData.type == 'voting'}
            <span>Geheim bis zur Proklamation</span>
          {:else}
            <span>{itemData.options.likes}</span>
          {/if}
        {/if}

      </div>
    {/if}
  </div>
{:catch error}
  error
{/await}
