import { nanoid } from 'nanoid';

import { icons } from './icons';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
dayjs.locale('de');
dayjs.extend(relativeTime);

export class Item {
  public get icon(): string {
    return icons[this.type];
  }
  public get time(): string {
    return dayjs(this.timestamp * 1000).fromNow();
  }

  constructor(
    public type:
      | 'video'
      | 'voting'
      | 'donation'
      | 'welcome'
      | 'intro'
      | 'article'
      | 'whatsapp',
    public heading: string,
    public body: string,
    public footer: string,
    public options: Object = {},
    public timestamp: number = Date.now() * 1000,
    public id: string = nanoid()
  ) {}
}
