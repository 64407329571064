<script lang="ts">
  import Loader from "./Loader.svelte";
  import dayjs from "dayjs";
  export let videoId: string;
</script>

<style>
  .container {
    width: 100%;
  }
</style>

<div class="container">
  <div class="frame" style="padding:56.25% 0 0 0; position: relative">
    <iframe
      title="Gifhorner Schützenfest Video"
      src="https://player.vimeo.com/video/{videoId}?autoplay=1&color=1cd06a"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen />
  </div>
</div>
