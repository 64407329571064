import { writable } from 'svelte/store';

function nameStore() {
  const { subscribe, set, update } = writable('');

  return {
    subscribe,
    change: (value: string) => {
      set(value);
      localStorage.setItem('name', value);
    },
    set,
  };
}

class Global {
  public name = nameStore();
  public video = writable('');

  constructor() {
    // @ts-ignore
    this.name.set(localStorage.getItem('name'));
    this.name.subscribe((value) => {
      console.log(`Name lautet ${value}.`);
    });
    this.video.subscribe((value) => {
      console.log(`Schaue Video ${value}.`);
    });
  }
}

export const GlobalData = new Global();
