<script lang="ts">
  import DonationForm from "./pages/DonationForm.svelte";
  import CodeCheck from "./pages/CodeCheck.svelte";
  import Footer from "./components/Footer.svelte";
  import Donation from "./pages/Donation.svelte";
  import Chat from "./components/Chat.svelte";
  import Reset from "./styles/Reset.svelte";
  import Global from "./styles/Global.svelte";

  import Header from "./components/Header.svelte";

  import Router from "svelte-spa-router";
  import Home from "./pages/Home.svelte";
  import Feed from "./pages/Feed.svelte";
  import Voting from "./pages/Voting.svelte";
  import Slot from "./pages/Slot.svelte";

  const routes = {
    "/": Home,
    "/videos": Feed,
    "/voting": Voting,
    "/losbude": Slot,
    "/donation": Donation,
    "/check": CodeCheck,
    "/donate": DonationForm
  };

  import { fade, fly } from "svelte/transition";
</script>

<style lang="scss">
  .logos {
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba($base-black, $alpha: 0.05);
    padding: 1.4rem 1rem;
    border-radius: 10px;

    margin: 1rem 0;

    img {
      opacity: 0.8;
      height: 1.6rem;
      margin: 0.4rem;
    }
  }
</style>

<!-- Styles -->
<Reset />
<Global />

<!-- Header -->
<Header />

<!-- Routing -->
<main class="wrap padding">
  <div class="logos">
    <a target="_blank" href="https://www.stadt-gifhorn.de/sv_gifhorn/">
      <img src="/logos/38.svg" alt="" />
    </a>
    <a target="_blank" href="https://www.sparkasse-cgw.de/de/home.html">

      <img src="/logos/37.svg" alt="" />
    </a>

    <a target="_blank" href="https://www.pingel-kfz.de">

      <img src="/logos/18.svg" alt="" />
    </a>

    <a target="_blank" href="https://www.bettina-harms.de">

      <img src="/logos/19.svg" alt="" />
    </a>

    <a target="_blank" href="https://www.physiocompact-gf.de">

      <img src="/logos/36.svg" alt="" />
    </a>

  </div>
  <Router {routes} />
  <script src="https://player.vimeo.com/api/player.js">

  </script>
  <Footer />

</main>

<Chat />
